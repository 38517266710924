import React from 'react';
import AudioTraining from './components/AudioTraining';

function App() {
  return (
    <div className="bg-gray-100 min-h-screen flex items-center justify-center">
      <AudioTraining />
    </div>
  );
}

export default App;