import React, { useState, useRef, useEffect } from 'react';

const AudioTraining = () => {
  const [pauseBetweenQA, setPauseBetweenQA] = useState(3000);
  const [pauseAfterQuestion, setPauseAfterQuestion] = useState(2000);
  const [audioGroup, setAudioGroup] = useState('tts-1');
  const [isGenerating, setIsGenerating] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentAudioStartTime, setCurrentAudioStartTime] = useState(0);
  const [audioReady, setAudioReady] = useState(false);
  const [isAudioTransitioning, setIsAudioTransitioning] = useState(false);

  const audioContextRef = useRef(null);
  const audioBuffersRef = useRef([]);
  const currentIndexRef = useRef(0);
  const timeoutRef = useRef(null);
  const currentAudioSourceRef = useRef(null);

  useEffect(() => {
    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (isPlaying && audioReady) {
      console.log('Effect triggered: isPlaying is true and audio is ready');
      playNextAudio();
    }
  }, [isPlaying, audioReady]);

  const fetchAudioBuffer = async (url) => {
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();
    return await audioContextRef.current.decodeAudioData(arrayBuffer);
  };

  const loadAudioFiles = async () => {
    console.log('loadAudioFiles started');
    setIsGenerating(true);
    setIsPlaying(false);
    setAudioReady(false);
    clearTimeout(timeoutRef.current);
    
    // Stop any currently playing audio
    if (audioContextRef.current.state !== 'closed') {
      audioContextRef.current.close();
    }
    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();

    const response = await fetch(`/${audioGroup}/output_data.json`);
    const data = await response.json();

    audioBuffersRef.current = [];
    for (const qa of data) {
      const questionBuffer = await fetchAudioBuffer(`/${qa.question.audio}`);
      const answerBuffer = await fetchAudioBuffer(`/${qa.answer.audio}`);
      audioBuffersRef.current.push({ questionBuffer, answerBuffer });
    }

    console.log('All audio buffers loaded, count:', audioBuffersRef.current.length);

    setIsGenerating(false);
    currentIndexRef.current = 0;
    
    console.log('Setting audioReady to true');
    setAudioReady(true);
    
    console.log('Setting isPlaying to true');
    setIsPlaying(true);
  };

  const stopCurrentAudio = () => {
    if (currentAudioSourceRef.current) {
      currentAudioSourceRef.current.stop();
      currentAudioSourceRef.current.disconnect();
      currentAudioSourceRef.current = null;
    }
    clearTimeout(timeoutRef.current);
  };

  const rewindAudio = () => {
    if (audioBuffersRef.current.length === 0 || isAudioTransitioning) return;
    
    setIsAudioTransitioning(true);
    const currentTime = audioContextRef.current.currentTime;
    const timeSinceStart = currentTime - currentAudioStartTime;

    stopCurrentAudio();

    setTimeout(() => {
      if (timeSinceStart > 3) {
        // Restart current question
        playNextAudio();
      } else {
        // Go to previous question
        currentIndexRef.current = (currentIndexRef.current - 1 + audioBuffersRef.current.length) % audioBuffersRef.current.length;
        playNextAudio();
      }
      setIsAudioTransitioning(false);
    }, 100); // Small delay to ensure audio has stopped
  };

  const fastForwardAudio = () => {
    if (audioBuffersRef.current.length === 0 || isAudioTransitioning) return;
    
    setIsAudioTransitioning(true);
    stopCurrentAudio();

    setTimeout(() => {
      currentIndexRef.current = (currentIndexRef.current + 1) % audioBuffersRef.current.length;
      playNextAudio();
      setIsAudioTransitioning(false);
    }, 100); // Small delay to ensure audio has stopped
  };

  const playNextAudio = () => {
    console.log('playNextAudio called, isPlaying:', isPlaying, 'audioReady:', audioReady);
    if (!isPlaying || !audioReady || isAudioTransitioning) {
      console.log('Conditions not met, returning without playing audio');
      return;
    }

    stopCurrentAudio();
    audioContextRef.current.resume();
    const { questionBuffer, answerBuffer } = audioBuffersRef.current[currentIndexRef.current];
    
    const questionSource = audioContextRef.current.createBufferSource();
    questionSource.buffer = questionBuffer;
    questionSource.connect(audioContextRef.current.destination);
    questionSource.start();
    currentAudioSourceRef.current = questionSource;
    setCurrentAudioStartTime(audioContextRef.current.currentTime);

    questionSource.onended = () => {
      timeoutRef.current = setTimeout(() => {
        const answerSource = audioContextRef.current.createBufferSource();
        answerSource.buffer = answerBuffer;
        answerSource.connect(audioContextRef.current.destination);
        answerSource.start();
        currentAudioSourceRef.current = answerSource;

        answerSource.onended = () => {
          timeoutRef.current = setTimeout(() => {
            currentIndexRef.current = (currentIndexRef.current + 1) % audioBuffersRef.current.length;
            playNextAudio();
          }, pauseBetweenQA);
        };
      }, pauseAfterQuestion);
    };
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      setIsPlaying(false);
      stopCurrentAudio();
      audioContextRef.current.suspend();
    } else {
      setIsPlaying(true);
      audioContextRef.current.resume();
      playNextAudio();
    }
  };

  return (
    <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
      <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Audio Training</h1>
      <div className="mb-4">
        <label htmlFor="pauseBetweenQA" className="block text-sm font-medium text-gray-700 mb-1">
          Pause between Q&A pairs (ms):
        </label>
        <input
          type="number"
          id="pauseBetweenQA"
          value={pauseBetweenQA}
          onChange={(e) => setPauseBetweenQA(parseInt(e.target.value))}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="pauseAfterQuestion" className="block text-sm font-medium text-gray-700 mb-1">
          Pause after question (ms):
        </label>
        <input
          type="number"
          id="pauseAfterQuestion"
          value={pauseAfterQuestion}
          onChange={(e) => setPauseAfterQuestion(parseInt(e.target.value))}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="audioGroup" className="block text-sm font-medium text-gray-700 mb-1">
          Audio Output Group:
        </label>
        <select
          id="audioGroup"
          value={audioGroup}
          onChange={(e) => setAudioGroup(e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="tts-1">OpenAI: TTS-1 ($0.68/hour)</option>
          <option value="tts-1-hd">OpenAI: TTS-1-HD ($1.35/hour)</option>
          <option value="elevenlabs">ElevenLabs Multilingual V2, Lily voice ($5.40/hour)</option>
        </select>
      </div>
      <button
        onClick={loadAudioFiles}
        disabled={isGenerating}
        className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 mb-4"
      >
        {isGenerating ? 'Loading...' : 'Generate Audio Training'}
      </button>
      {audioBuffersRef.current.length > 0 && (
        <div className="flex justify-between items-center mb-4">
          <button
            onClick={rewindAudio}
            className="bg-yellow-500 text-white py-2 px-4 rounded-md hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
          >
            ⏪ Rewind
          </button>
          <button
            onClick={togglePlayPause}
            className="bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          >
            {isPlaying ? '⏸ Pause' : '▶ Play'}
          </button>
          <button
            onClick={fastForwardAudio}
            className="bg-yellow-500 text-white py-2 px-4 rounded-md hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
          >
            Fast Forward ⏩
          </button>
        </div>
      )}
    </div>
  );
};

export default AudioTraining;